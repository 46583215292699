import bvi from '../../components/bvi/js/bvi';
import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    const bviItem = new bvi.Bvi();

    document.addEventListener('click', (ev)=>{
        let active = document.querySelector('.bvi-active');
        if (active) {
            let target = ev.target;
            let body = document.querySelector('.bvi-body');
            let panel = document.querySelector('.bvi-panel');
            if (body.contains(target) && !panel.classList.contains('bvi-panel-hide')
                && !target.classList.contains('bvi-link-fixed-top')) {
                let hideButton = document.querySelector("[data-bvi='panel-hide']");
                hideButton.click();
            }
        }
    });
})
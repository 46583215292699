import {onDomReady} from "../../components/dynamic/observer";

/**
 * TODO
 * - появление стрелки
 */
class Cursor {
    cursor = undefined;
    current = undefined;
    move = undefined;
    ease = undefined;
    expandItems = [];
    arrowItems = [];
    dragItems = [];
    largeMinWidth = undefined;

    removeClasses = [
      '_expand',
      '_arrow',
      '_arrows_left',
      '_arrows_right',
      '_drag',
      '_delay'
    ];

    constructor() {
        this.move = document.querySelector('[data-cursor]');
        const rootStyles = getComputedStyle(document.documentElement);
        this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1200;

        if (document.body.getBoundingClientRect().width >= this.largeMinWidth) {
            document.body.classList.add('_hide-cursor');
            this.cursor = {
                x: 0,
                y: 0,
            };
            this.current = {
                x: 0,
                y: 0,
            };
            this.ease = 1;
            this.order = [];
            this.currentState = [];
            this.lastState = [];
            this.itemCollector();

            this.eventListeners();
            this.update();
        } else {
            this.move.classList.add('_hide')
        }
    }

    update() {
        this.lerp();
        this.move.style.transform = `matrix(1, 0, 0, 1, ${this.current.x}, ${this.current.y})`;
        this.defineState();
        const instance = this;
        window.requestAnimationFrame(() => {
            instance.update();
        });
    }

    itemCollector() {
        this.expandItems = document.querySelectorAll('[data-cursor-expand]:not([data-cursor-initialized])');
        this.arrowItems = document.querySelectorAll('[data-cursor-arrow]:not([data-cursor-initialized])');
        this.arrowsItems = document.querySelectorAll('[data-cursor-arrows]:not([data-cursor-initialized])');
        this.dragItems = document.querySelectorAll('[data-cursor-drag]:not([data-cursor-initialized])');
    }

    lerp() {
        this.current.x = (1 - this.ease) * this.current.x + this.ease * this.cursor.x;
        this.current.y = (1 - this.ease) * this.current.y + this.ease * this.cursor.y;
    }

    defineState() {
        const length = this.order.length;
        if (length) {
            this.lastState = Array.from(this.currentState);
            this.order.forEach((state) => {
                if (state.enter && !this.currentState.find((current) => current === state.name)) {
                    this.currentState.push(state.name);
                } else if (!state.enter) {
                    this.currentState = this.currentState.filter(current => current !== state.name)
                }
            });
            this.currentState = this.currentState.sort();
            this.order = [];
            if (this.currentState !== this.lastState) {
                this.applyClass(this.defineClass());
            }
        }
    }

    defineClass() {
        let className = ''

        if (this.currentState.length) {
            // Если одна из стрелок - возращаем стрелку
            if (this.currentState.find((name) => name === 'arrows-left')) {
                return `_arrows_left`;
            }
            if (this.currentState.find((name) => name === 'arrows-right')) {
                return `_arrows_right`;
            }

            const isExpand = this.currentState.find((name) => name === 'expand');

            if (this.currentState.find((name) => name === 'drag') && !isExpand) {
                return `_drag`;
            }

            // полый круг
            if (isExpand) {
                className += ' _expand';
            }
        }

        if (this.lastState.find((name) => name === 'expand')) {
            className += ' _delay';
        }

        return className;
    }

    applyClass(className) {
        const classArray = className.split(' ');
        const removeClasses = this.removeClasses.filter((removeClass) => !classArray.includes(removeClass));
        removeClasses.forEach((removeClass) => this.move.classList.remove(removeClass));
        classArray.forEach((addClass) => {
            if (addClass) {
                this.move.classList.add(addClass);
            }
        });
    }

    initLoadedListeners() {
        this.expandItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                this.order.push({enter: true, name: 'expand'});
            });
            item.addEventListener('mouseleave', () => {
                this.order.push({enter: false, name: 'expand'});
            });
        });

        this.arrowItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                this.order.push({enter: true, name: 'arrow'});
            });
            item.addEventListener('mouseleave', () => {
                this.order.push({enter: false, name: 'arrow'});
            });
        });

        this.arrowsItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');

            if (item.hasAttribute('data-cursor-arrows-left')) {
                item.addEventListener('mouseenter', () => {
                    this.order.push({enter: true, name: 'arrows-left'});
                });
                item.addEventListener('mouseleave', () => {
                    this.order.push({enter: false, name: 'arrows-left'});
                });
            } else {
                item.addEventListener('mouseenter', () => {
                    this.order.push({enter: true, name: 'arrows-right'});
                });
                item.addEventListener('mouseleave', () => {
                    this.order.push({enter: false, name: 'arrows-right'});
                });
            }
        });

        this.dragItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                this.order.push({enter: true, name: 'drag'});
            });
            item.addEventListener('mouseleave', () => {
                this.order.push({enter: false, name: 'drag'});
            });
        });
    }

    eventListeners() {
        const instance = this;
        window.addEventListener('mousemove', (e) => {
            instance.cursor.x = e.clientX;
            instance.cursor.y = e.clientY;
        }, false);


        window.addEventListener('cursorMove', (e) => {
            instance.cursor.x = e.detail.offsetX;
            instance.cursor.y = e.detail.offsetY;
        })

        this.initLoadedListeners();

        document.addEventListener('DOMContentMutated', () => {
            this.itemCollector();
            this.initLoadedListeners();
        });
    }
}

// TODO Первоначальный курсор, пока он еще не зашел ни на что
function init() {
    const follower = new Cursor();
}

onDomReady(() => {init()});
function initMapYandex() {
    setBviBuiltActive();

    document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
        if (document.body.classList.contains('bvi-active')) {
            mapElement.dataset.zoom = '18';
        }

        let myMap = new ymaps.Map(mapElement, {
            center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
            zoom: mapElement.dataset.zoom,
            controls: ['zoomControl', 'fullscreenControl']
        }, {
            searchControlProvider: 'yandex#search'
        });
        let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: mapElement.dataset.mark,
            // Размеры метки.
            iconImageSize: [50, 58],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [15, -50]
        });


        myMap.behaviors.disable('scrollZoom');
        if (window.innerWidth <= 1024) {
            myMap.behaviors.disable('drag');
        }
        myMap.geoObjects.add(myPlacemark);
    });

    setTimeout(() => {
        setBviNoStyles();
        setBviBuiltActive();
    }, 2000);

    document.addEventListener('bvi-active', () => {
        setBviBuiltActive();
    });
}

function setBviBuiltActive() {
    let bviBody = document.querySelector(".bvi-body")
    if (bviBody) {
        bviBody.dataset.bviBuiltelements = 'true';
    }
}

function setBviNoStyles() {
    document.querySelectorAll('ymaps').forEach((item) => {
        item.classList.add('bvi-no-styles');
        item.classList.add('bvi-no-font-size');

        item.querySelectorAll('a').forEach((linkItem) => {
            linkItem.classList.add('bvi-no-styles');
            linkItem.classList.add('bvi-no-font-size');
        })
    })
}

window.addEventListener('load', () => {
    if (window.google) {
        initMapGoogle();
    }
    if (window.ymaps) {
        ymaps.ready(initMapYandex);
    }
});

import BviDataSelectorsKiller from "./bvi-data-selectors-killer";
import {onDomReady} from "../../../components/dynamic/observer";

class BviHelper {
    constructor() {
        this.body = document.body;
        this.dataSelectors = [];
        this.init();
        this.addListeners();
        this.checkOnLoaded();
    }

    init() {
        this.dataSelectors = [
            new BviDataSelectorsKiller('data-modal'),
        ];
    }

    addListeners() {
        document.addEventListener('bvi-active', () => {
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.removeDataSelector();
            });
        });

        document.addEventListener('bvi-disable', () => {
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.addDataSelector();
            });
        });
    }

    checkOnLoaded() {
        if (this.body.classList.contains('bvi-active')) {
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.removeDataSelector();
            });
        } else {
            this.dataSelectors.forEach((dataSelector) => {
                dataSelector.addDataSelector();
            });
        }
    }
}

onDomReady(() => {
    setInterval(() => {
        if (document.body.classList.contains('bvi-active')) {
            new BviHelper();
        }
    }, 2000);
})
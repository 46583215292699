const header = document.querySelector('[data-header]');
const hamburger = document.querySelector('[data-header-hamburger]');
const menu = document.querySelector('[data-header-menu]');
const layout = document.querySelector('.layout');
let links = document.querySelectorAll('[data-drop-link]');

if (header && hamburger && menu) {
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('_open');
        header.classList.toggle('_black');
        menu.classList.toggle('_open');
        layout.classList.toggle('_opened');
    });

    links.forEach((link) => {
        link.addEventListener('click', ()=>{
            hamburger.classList.remove('_open');
            header.classList.remove('_black');
            menu.classList.remove('_open');
            layout.classList.remove('_opened');
        })
    });
}
